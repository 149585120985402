import Survey from "./Survey";

export default class PublishedSurvey {
  constructor() {
    this.id = null;
    this.survey = new Survey();
    this.publishDateTime = null;
    this.endDateTime = null;
    this.anonymous = false;
    this.results = [];
    this.completedEvaluators = [];
    this.uncompletedEvaluators = [];
  }
}
